import CodeMirror from "./libs/codemirror/lib/codemirror.js";
import "./libs/codemirror/mode/javascript/javascript.js";
import './libs/codemirror/theme/monokai.css';

mocha.setup('bdd');

function qs(selector) {
  return document.querySelector(selector);
}

function qsa(selector) {
  return document.querySelectorAll(selector);
}

window.console = {
  log: function(message) {
    var displayElm = document.createElement('p');
    displayElm.innerHTML = `${message}`;
    displayElm.classList.add('output-elm');
    qs('#output').insertBefore(displayElm, qs('#mocha'));
  }
}

var codeEditor = CodeMirror.fromTextArea(qs('#editor'), {
  mode: "javascript",
  lineNumbers: true,
});

qs('#hide-intro').addEventListener('click', () => {
  qs('.intro').classList.add('hidden');
  qs('.overlay').classList.add('hidden');
});
qs('#help-icon').addEventListener('click', () => {
  qs('.help').classList.remove('hidden');
  qs('.overlay').classList.remove('hidden');
})
qs('#hide-help').addEventListener('click', () => {
  qs('.help').classList.add('hidden');
  qs('.overlay').classList.add('hidden');
})

qs('#valider').addEventListener('click', () => {
  mocha.suite.tests = [];
  if (qsa('.output-elm').length > 0) {
    qsa('.output-elm').forEach(element => {
      element.remove();
    });
  }

  qs('#mocha').innerHTML = "";
  var codeScript = document.createElement("script");
  codeScript.innerHTML = codeEditor.getValue('');
  document.body.appendChild(codeScript);  

  eval(qs('#editor-test').textContent);

  
  var expect = chai.expect;
  
  mocha.checkLeaks();
  mocha.run();  

  fixMochaUI();

  codeScript.remove();

  window.setTimeout(() => {    
  
    if (qs('#mocha-stats .passes em').textContent === "1") {
      qs('#subscribe').style.display = 'block';
      qs('.overlay').classList.remove('hidden');
    }
  }, 1000);


});



/**
 * remove mocha links
 */
function fixMochaUI() {
  qsa('.replay').forEach(elm => {
    elm.addEventListener('click', function(e) {
      e.preventDefault();
    })
  });
  qsa('#mocha .suite h1').forEach(elm => {
    elm.addEventListener('click', function(e) {
      e.preventDefault();
    })
  });
}
